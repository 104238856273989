import { BoardAccessLevelEnum } from '@services/ShipmentDataGatewayService';
import { useCurrentUserAccessLevel } from '../../boardView/hooks/useCurrentUserAccessLevel';

export const defaultOptions = [
  {
    label: 'Viewer',
    subText: 'View shipments, but not comments',
    value: BoardAccessLevelEnum.Viewer,
  },
  {
    label: 'Commenter',
    subText: 'View shipments and comments, but not documents',
    value: BoardAccessLevelEnum.Commenter,
  },
  {
    label: 'Collaborator',
    subText: 'View shipments, comments and documents',
    value: BoardAccessLevelEnum.Collaborator,
  },
];

const removeAccessOption = {
  label: 'Remove access',
  value: 'removeAccess',
  isAction: true,
};

export const useGetAccessRolesListOptions = (boardId: string) => {
  const { isCollaborator } = useCurrentUserAccessLevel(boardId);

  if (isCollaborator) {
    return [...defaultOptions, removeAccessOption];
  }
  return defaultOptions;
};
